<div class="mx-4 md:mx-52 pt-8">

    <ng-container *ngIf="firstName else loader">

        <div class="flex flex-col gap-y-4">

            <h1 class="font-bold mb-8 text-4xl text-color-pc animate-fade">Hi, {{ firstName }}</h1>

            <div class="flex flex-col md:flex-row gap-4 gap-y-4 items-stretch">

                <div class="basis-1/2 ">
                    <div class="mb-4 bg-white rounded-lg shadow-md h-full">
                        <div class="flex flex-col p-4 h-full">

                            <div class="flex  items-center justify-between px-2 py-1 rounded-md">
                                <mat-icon aria-hidden="false" aria-label="Menu icon" fontIcon="school"
                                    class="mat-icon-md mb-3 mt-4 text-[#217C79]"></mat-icon>

                                <div class="flex justify-center items-center bg-[#217c79c9] p-1 rounded-2xl px-3">
                                    <h4 class="font-semibold text-md text-white">Free</h4>
                                </div>
                            </div>

                            <h2 class="text-color-pc text-2xl mb-2 font-black">Academy</h2>

                            <h2 class="mb-4 text-base">Enhance your business acumen with our academy, offering valuable
                                resources and expert knowledge.</h2>
                            <button class="w-full md:w-48 mb-2 justify-self-end mt-auto !rounded-xl" mat-raised-button
                                color="primary" [routerLink]="['/academy']">Start Learning</button>

                        </div>
                    </div>
                </div>

                <div *ngIf="loaded_subscription; else loader" class="basis-1/2">

                    <div *ngIf="!has_subscription" class="mb-4 bg-white rounded-lg shadow-md h-full">
                        <div class="flex flex-col p-4 h-full">
                            <div class="flex  items-center justify-between px-2 py-1 rounded-md">
                                <mat-icon aria-hidden="false" aria-label="Menu icon" fontIcon="business_center"
                                    class="mat-icon-md mb-3 mt-4 text-[#217C79]"></mat-icon>

                                <div
                                    class="flex justify-center text-[#062A30] items-center bg-[#ffcc00] p-1 rounded-2xl px-3">
                                    <mat-icon aria-hidden="false" aria-label="Menu icon" fontIcon="diamond"
                                        class="pt-[1.5px] mat-icon-sm"></mat-icon>
                                    <h4 class="font-semibold text-md pl-1">Premium</h4>
                                </div>
                            </div>
                            <h2 class="text-color-pc text-2xl mb-2 font-black">Exseede ERP for SMEs</h2>

                            <h2 class="mb-4 text-base">Digitalizing your core business processes for enhanced
                                transparency,
                                decision making and performance.</h2>
                            <button class="w-full md:w-48 mb-2 justify-self-end mt-auto !rounded-xl" mat-raised-button
                                color="primary" [routerLink]="['/erp']">Get Started</button>

                        </div>
                    </div>

                    <div *ngIf="has_subscription" class="mb-4 bg-white rounded-lg shadow-md h-full">
                        <div class="flex flex-col p-4 h-full">

                            <div class="flex  items-center justify-between px-2 py-1 rounded-md">
                                <mat-icon aria-hidden="false" aria-label="Menu icon" fontIcon="business_center"
                                    class="mat-icon-md mb-3 mt-4 text-[#217C79]"></mat-icon>

                                <div *ngIf="is_in_trial"
                                    class="flex justify-center text-[#062A30] items-center bg-[#ffcc00] p-1 rounded-2xl px-3">
                                    <mat-icon aria-hidden="false" aria-label="Menu icon" fontIcon="diamond"
                                        class="pt-[1.5px] mat-icon-sm"></mat-icon>
                                    <h4 class="font-semibold text-m pl-1">Trial Active</h4>
                                </div>
                                <div *ngIf="!is_in_trial"
                                    class="flex justify-center text-[#062A30] items-center bg-[#ffcc00] p-1 rounded-2xl px-3">
                                    <mat-icon aria-hidden="false" aria-label="Menu icon" fontIcon="diamond"
                                        class="pt-[1.5px] mat-icon-sm"></mat-icon>
                                    <h4 class="font-semibold text-m pl-1">Active</h4>
                                </div>

                            </div>
                            <h2 class="text-color-pc text-2xl mb-2 font-black capitalize">{{
                                filteredPackage()[0].name.toLowerCase()
                                }}</h2>

                            <h2 class="mb-4 text-base">
                                Your current plan is <span class="lowercase">{{ filteredCurrentPlan[0].planDetail |
                                    titlecase
                                    }}</span>
                            </h2>

                            <div class="flex flex-col mt-auto">

                                <p *ngIf="site_status === 'enabled' && site_url" class="mb-3">Check your email for your
                                    login credentials</p>
                                <a *ngIf="site_status === 'enabled' && site_url; else loadingSite" [href]="site_url"
                                    target="_blank" mat-raised-button color="primary"
                                    class=" md:w-48 w-full justify-self-end md:mr-12 py-2 text-center text-white !rounded-xl mt-auto mb-2">
                                    Go to ERP Dashboard
                                </a>


                                <ng-template #loadingSite>
                                    <h2 class="mb-2 mt-4 text-base">Site Setup in progress:</h2>
                                    <app-loader></app-loader>
                                    <p>You will receive an email with your login credentials when your site is ready.
                                    </p>
                                </ng-template>

                            </div>

                        </div>
                    </div>

                </div>

            </div>

            <div class="flex flex-col md:flex-row gap-4">


                <div class="basis-1/2">
                    <div class="mb-4 bg-white rounded-lg shadow-md h-full">
                        <div class="flex flex-col p-4 h-full">

                            <div class="flex  items-center justify-between px-2 py-1 rounded-md">
                                <mat-icon aria-hidden="false" aria-label="Menu icon" fontIcon="volunteer_activism"
                                    class="mat-icon-md mb-3 mt-4 text-[#217C79]"></mat-icon>

                                <div class="flex justify-center items-center bg-[#217c79c9] p-1 rounded-2xl px-3">
                                    <h4 class="font-semibold text-md text-white">Free</h4>
                                </div>
                            </div>

                            <h2 class="text-color-pc text-2xl mb-2 font-black">Funding Database</h2>
                            <h2 class="mb-4 text-base">Explore an extensive database of funding opportunities to fuel
                                your
                                business growth and expansion.</h2>
                            <button class="mb-2 mt-auto !rounded-xl md:w-48 w-full" mat-raised-button color="primary"
                                [routerLink]="['/funding']">Explore Funding Options</button>

                        </div>
                    </div>
                </div>


                <div class="basis-1/2">
                    <div class="mb-4 bg-white rounded-lg shadow-md h-full">
                        <div class="flex flex-col p-4 h-full">

                            <div class="flex  items-center justify-between px-2 py-1 rounded-md">
                                <mat-icon aria-hidden="false" aria-label="Menu icon" fontIcon="query_stats"
                                    class="mat-icon-md mb-3 mt-4 text-[#217C79]"></mat-icon>

                                <div *ngIf="!has_subscription"
                                    class="flex justify-center text-[#062A30] items-center bg-[#ffcc00] p-1 rounded-2xl px-3">
                                    <mat-icon aria-hidden="false" aria-label="Menu icon" fontIcon="diamond"
                                        class="pt-[1.5px] mat-icon-sm"></mat-icon>
                                    <h4 class="font-semibold text-md pl-1">Premium</h4>
                                </div>

                                <div *ngIf="has_subscription && is_in_trial"
                                    class="flex justify-center text-[#062A30] items-center bg-[#ffcc00] p-1 rounded-2xl px-3">
                                    <mat-icon aria-hidden="false" aria-label="Menu icon" fontIcon="diamond"
                                        class="pt-[1.5px] mat-icon-sm"></mat-icon>
                                    <h4 class="font-semibold text-m pl-1">Trial Active</h4>
                                </div>
                                <div *ngIf="has_subscription && !is_in_trial"
                                    class="flex justify-center text-[#062A30] items-center bg-[#ffcc00] p-1 rounded-2xl px-3">
                                    <mat-icon aria-hidden="false" aria-label="Menu icon" fontIcon="diamond"
                                        class="pt-[1.5px] mat-icon-sm"></mat-icon>
                                    <h4 class="font-semibold text-m pl-1">Active</h4>
                                </div>
                            </div>

                            <h2 class="text-color-pc text-2xl mb-2 font-black">Financial Insights</h2>

                            <h2 class="mb-4 text-base">Get in-depth insights into your company's performance with our
                                comprehensive financial analysis tool.</h2>
                            <button class="w-full mt-auto md:w-48 mb-2 justify-self-end !rounded-xl" mat-raised-button
                                color="primary" [routerLink]="['/financial-wizard']">Get Insights</button>

                        </div>
                    </div>
                </div>
            </div>

        </div>

    </ng-container>
</div>

<ng-template #loader>
    <app-loader></app-loader>
</ng-template>